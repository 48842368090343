<template>
    <div>
      <Card>
        <div class="baslik w-100">
          <nav class="navbar navbar-expand-lg navbar-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li
                  class="nav-item"
                  :class="{ active: isActive('purpose-list') }"
                >
                  <router-link class="nav-link" :to="{ name: 'purpose-list' }"
                    >Fiili Kullanım Nitelikleri</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  :class="{ active: isActive('usage-list') }"
                >
                  <router-link
                    class="nav-link"
                    :to="{ name: 'usage-list' }"
                    >Fiili Kullanım Amaçları</router-link
                  >
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <transition name="fade-in-up">
          <router-view></router-view>
        </transition>
      </Card>
    </div>
  </template>
  <script>
  export default {
    methods: {
      isActive(routeName) {
        return this.$route.name === routeName;
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .nav-item{
    &.active{
      a{
        border-bottom: 2px solid $dveb-primary;
      }
    }
  }
  </style>